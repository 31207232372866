.GIACertificate {
    @apply !size-20 xl:relative xl:!left-[50%] xl:!top-[auto] xl:-translate-x-2/4 xl:!bottom-0 xl:!size-16 2xl:!size-[calc(var(--thumbHeight))] !object-contain p-2 absolute z-[1] cursor-pointer bg-grayLight ;
}
.closeIcon {
    @apply min-w-[auto] m-0 h-auto xl:p-1;
}
.imgLoaded{
    @apply w-full h-[90vh] xl:h-[655px] lg:h-[76.5vw] lg:max-h-[670px] flex flex-col;
}
.imgLoaded :global(.loading-root){
    @apply left-0 h-full;
}
.imgLoaded .imgItem{
    @apply max-h-[90vh] overflow-hidden relative flex-1;
}