.root {
    @apply relative z-0 w-full ;
}
.input {
    @apply block w-full appearance-none focus:outline-none h-14 pt-5 px-3 pb-0 text-sm bg-white text-black border focus:ring-0 focus:ring-offset-0 font-medium;
}
.input::placeholder {
    @apply opacity-0;
}
.label {
    @apply absolute text-black top-1/2 translate-y-[-50%]  block text-sm mb-1 left-3 cursor-text font-medium text-default;
}
.input:focus ~ label,
.input:not(:placeholder-shown) ~ label {
    @apply top-[1.2em] translate-y-[-50%] text-[12px] text-default;
}

.input:focus ~ label {
    @apply  transition-all;
}
.input ~ label {
    @apply transition-all;
}
.counter {
    @apply absolute right-4 top-1/2 -translate-y-1/2 pt-[0.2em] text-gray-500;
}
.label {
    @apply pointer-events-none;
}
.errorMessage {
    @apply text-red-500 text-[13px] flex w-full mt-1.5 font-medium gap-0.5;
}