:global(.rc-slider-handle) {
  @apply !opacity-100 lg:w-[18px] lg:h-[18px] lg:-mt-[7px];
}
:global(.rc-slider-with-marks *) {
  /* @apply transition-[0.25s] duration-[ease]; */
}
:global(.rc-slider-handle-dragging),
:global(.rc-slider-handle:hover),
:global(.rc-slider-handle:focus-visible) {
  @apply !shadow-[0_0_0_2px] !shadow-black/[0.2];
}

/* e9e9e9 */
:global(.rc-slider-step > .rc-slider-dot) {
  @apply bottom-0 h-[4px] w-[12px] bg-grayLight rounded-none;
}
:global(.rc-slider-step > .rc-slider-dot.rc-slider-dot-active) {
}
:global(.rc-slider-step > .rc-slider-dot:before),
:global(.rc-slider-step > .rc-slider-dot:after) {
  content: "";
  @apply h-full w-full absolute bg-[#e9e9e9];
}
:global(.rc-slider-step > .rc-slider-dot.rc-slider-dot-active:before),
:global(.rc-slider-step > .rc-slider-dot.rc-slider-dot-active:after) {
  @apply bg-secondary;
}
:global(.rc-slider-step > .rc-slider-dot:before) {
  @apply  left-0;
}
:global(.rc-slider-step > .rc-slider-dot:after) {
  @apply  right-0;
}
:global(.rc-slider-step > .rc-slider-dot:last-child:after),
:global(.rc-slider-step > .rc-slider-dot:first-child:before) {
  opacity: 0;
}
:global(.rc-slider-mark-text-active) {
  color: black !important;
}
:global(.rc-slider-mark-text) {
  @apply top-[5px] max-w-[60px] font-medium;
}

.root {
  @apply w-[calc(100%-15px)] mx-auto mb-[35px];
}

@media (min-width: 768px) and (max-width: 1024px) {
  :global(.rc-slider-handle) {
    @apply !opacity-100 !w-[20px] !h-[20px] !-mt-[8px] before:w-12  before:h-12 before:bg-transparent rounded-full before:absolute before:top-1/2 before:left-1/2 before:-translate-x-1/2 before:-translate-y-1/2  relative;
  }
}
@media (min-width: 1025px) {
  :global(.rc-slider-handle) {
    @apply !opacity-100 !w-[14px] !h-[14px] !-mt-[5px] before:w-10  before:h-10 before:bg-transparent rounded-full before:absolute before:top-1/2 before:left-1/2 before:-translate-x-1/2 before:-translate-y-1/2  relative;
  }
}
@media (max-width: 767px) {
  :global(.rc-slider-handle) {
    @apply !opacity-100 !w-[14px] !h-[14px] !-mt-[5px] before:w-12  before:h-12 before:bg-transparent rounded-full before:absolute before:top-1/2 before:left-1/2 before:-translate-x-1/2 before:-translate-y-1/2  relative;
  }
}
