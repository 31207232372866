.root {
  @apply w-full h-full flex bg-borderGray py-10 overflow-auto;
}
.section {
  @apply w-full h-auto max-w-[1680px] mx-[auto] my-auto;
}
.w_container {
  @apply max-w-[940px] mx-auto after:clear-both after:content-["_"] after:table after:col-start-1 after:row-start-1 after:col-end-2 after:row-end-2 before:content-["_"] before:table before:col-start-1 before:row-start-1 before:col-end-2 before:row-end-2 lg:max-w-[728px] md:mx-0 sm:max-w-none;
}
.w_layout_blockContainer {
  @apply max-w-[940px] block mx-auto lg:max-w-[728px] md:max-w-none;
}
.container {
  @apply w-full max-w-[730px] px-5 min_2xxl:w-full min_2xxl:max-w-[730px] min_2xxl:px-5;
}
.logo {
  @apply justify-center items-center flex md:w-full md:max-w-none;
}
.logo_img {
  @apply md:w-full md:max-w-[240px];
}
.ring_builder {
  @apply gap-x-2.5 gap-y-0 flex-col justify-center items-center flex mt-[30px] min_2xxl:text-[80px] min_2xxl:mb-[30px] lg:my-5 md:mt-[20px] md:mb-2.5;
}
.title {
  @apply text-center text-black -tracking-[1.5px] lg:-tracking-[1px] sm:-tracking-normal text-[32px] leading-[1.3] mt-0 mb-2.5 pt-[0.07em] min_xl:text-[32px] min_2xxl:text-4xl lg:text-[26px] md:text-xl sm:text-base sm:mb-1.5 font-secondary font-normal;
}
.subtitle {
  @apply text-secondary tracking-[normal] text-2xl font-normal font-primary leading-[1.3] my-0 pt-[0.07em] min_xl:text-2xl min_2xl:text-2xl lg:text-xl lg:font-normal md:text-base sm:text-sm;
}
.bar {
  @apply justify-center items-center flex my-[30px] min_2xxl:justify-center min_2xxl:items-center min_2xxl:flex lg:my-5  md:w-full md:my-2.5 sm:mt-5 sm:mb-2;
}
._3section {
  @apply w-full max-w-[660px] mx-auto my-0;
}
._3section_wrapper {
  @apply cursor-pointer max-w-full min-h-0 gap-x-5 gap-y-0 bg-white hover:bg-tableRowHover border justify-center items-center no-underline flex my-5 p-5 border-solid border-border hover:opacity-80 hover:border hover:shadow-[0_0_0_1px_rgba(94,119,151,0.3)] hover:border-secondaryButton  active:shadow-[0_0_0_3px_rgba(94,119,151,0.3)] focus:opacity-100 focus:shadow-[0_0_0_3px_rgba(94,119,151,0.3)] md:min-h-0 md:p-5 sm:gap-0 sm:flex-col sm:justify-center sm:items-center sm:my-5 sm:pl-5 sm:py-5;
}
._3section_img_wrapper {
  @apply min-w-[85px] w-[85px] sm:min-w-[60px] sm:max-w-[60px];
}
._3section_text_wrapper {
  @apply w-auto flex-col justify-center items-start flex p-0 md:w-auto sm:w-full sm:items-center;
}
._3section_title {
  @apply text-black text-base font-medium leading-[1.3] mt-0 pt-[0.07em] md:text-[15px] sm:text-center sm:text-sm font-primary leading-normal;
}
._3section_para {
  @apply text-secondary mt-[3px] text-sm text-left font-normal leading-normal mb-0 pt-[0.07em] md:text-[13px] sm:text-center sm:text-xs;
}
.barImg {
  @apply w-full max-w-[160px] lg:max-w-[120px];
}
