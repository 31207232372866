.iconColor {
    @apply text-black cursor-pointer;
}
.label_name {
    @apply capitalize;
}
.dialogBody {
    @apply border-t border-solid border-secondary/20 overflow-auto relative;
}
.dialogText {
    @apply flex gap-2 flex-col text-secondary;
}
.dialogTitle {
    @apply text-base font-primary font-medium;
}
.dialogParagraph {
    @apply text-sm text-gray-500 empty:hidden;
}
.iconWrp{
    @apply inline-flex ml-1.5;
}
.swatchText{}

.dialogDetail{}

.listDatatype > ul {
    @apply ml-[25px] mt-[8px] list-disc;
}
.dialogBody :global(.loading-root){
    @apply top-0 h-full bg-white;
}
:global(.tabbing) .iconWrp:focus{
    /*box-shadow: 0 0 0 1.5px rgb(0,0,0,0.2);*/
    outline: 1px solid black !important;
}