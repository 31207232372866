.root {
  @apply flex-1 z-[1] xl:overflow-visible overflow-auto;
}
:global(.byo_page_present) {
  @apply xl:h-full lg:h-auto md:h-auto;
}
:global(.safari-macos) .root {
  @apply overflow-x-hidden;
}
.container {
  @apply mx-auto flex items-start md:w-full max-w-site;
  @apply px-6 xl:px-0;
}
.slider {
  @apply inline-block max-w-full flex-1 sticky top-0 w-[calc(100%-500px)] px-5 z-[1];
  /* @apply xl:static xl:w-full xl:max-w-full xl:basis-full xl:px-0 md:max-w-full; */
}
.detail {
  @apply flex max-w-full w-[500px] basis-[500px] px-5 relative z-0 overflow-clip;
  @apply xl:w-full xl:max-w-[500px] xl:basis-full;
}
.detail :global .loading-root {
  @apply xl:h-[calc(20%-var(--stickyHeight))];
}
.viewRoot {
  /* @apply bg-grayLight; */
}
.viewRoot :global(.swiper-pagination) {
  @apply bottom-6 md:bottom-6;
}
.viewRoot *::selection {
  background-color: transparent;
}
.viewChange {
  @apply absolute top-3 right-3 z-[1] cursor-pointer;
}
.viewChange svg {
  @apply !h-6 !w-6;
}

.iframe {
  @apply pt-[calc(100vh-(var(--stickyHeight)+65px))] min-h-[400px] relative xl:pt-[100%];
}
.viewIframe {
  @apply absolute top-0 left-0 w-full h-full;
}
.inVisible {
  @apply invisible opacity-0 max-h-0 max-w-0 !pt-0 overflow-hidden min-h-0;
}
@media screen and (max-width: 1279px) {
  .slider {
    @apply sticky;
    /*pt-[580px]*/
    /*pt-[100%]*/
    /*position: absolute;*/
    position: fixed;
    top: 60px;
  }
  .slider:after {
    /*content: "";*/
    position: relative;
    display: flex;
    /*padding-top: 580px;*/
    pointer-events: none;
  }
  .viewRoot {
    @apply absolute left-0 top-0 w-full;
  }
  :global(.stickySlider .sliderWrapper) {
    @apply border-b-borderDark;
    box-shadow: 0 0 1px #9b9b9b;
  }
  :global(.slider-wrapper-stick) {
    position: sticky;
    top: 0;
    width: 100%;
    padding-top: 580px;
  }
}
@media screen and (min-width: 600px) and (max-height: 500px) and (max-width: 1279px) {
  :global(.slider-wrapper-stick) {
    padding-top: 300px;
  }
}
@media screen and (max-width: 579px) {
  .slider {
    /*@apply sticky;*/
    /*pt-[100%]*/
  }
  .slider:after {
    padding-top: 100%;
  }
  :global(.slider-wrapper-stick) {
    padding-top: 100%;
  }
}

@media screen and (max-width: 1024px) {
  .container {
    @apply flex-col items-center;
  }
  .slider {
    /* @apply inline-block max-w-full flex-1 sticky top-0 w-[calc(100%-500px)] px-5 z-[1]; */
    @apply w-full max-w-full basis-full px-0;
  }
}