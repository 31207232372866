.wrapper{
    @apply flex-1 flex flex-col overflow-auto;
    @apply md:overflow-hidden;
}
.tabs{
    @apply !relative flex-1 flex flex-col overflow-visible;
    @apply md:max-h-full md:overflow-hidden;
    /*@apply pt-[60px] relative;*/
}
:global(.sticky_filter) .tabs{
    @apply overflow-auto;
}
.tabs :global .stickyButton{
    @apply bottom-0 z-[11] md:right-[1.8rem];
}
.tabs :global .stickyButton.active{
    @apply bottom-[calc(var(--stickyHeight)+4.875rem)] right-[2.7rem] md:right-[1.8rem] ;
}
:global(.sticky_filter) .tabs :global .stickyButton.active{
    @apply bottom-[calc(var(--stickyHeight)+0.75rem)] md:bottom-[calc(var(--stickyHeight)+1.5rem)];
}
:global(.filterIsActive) .tabs :global .stickyButton{
    @apply bottom-[calc(var(--stickyHeight)+0.875rem)] z-[10];
}
.center{
    @apply w-full flex items-center justify-center text-[1.2rem] italic;
}
:global(.filterIsActive) .wrapper{
    @apply md:overflow-hidden;
}