.mainImageSlider {
  @apply max-w-[calc(100%-110px)] !inline-block bg-grayLight;
}
.mainImages {
  /*@apply p-10 ;*/
  /*remove after Demo*/
  @apply h-[calc(100vh-(var(--stickyHeight)+65px))] min-h-[400px] xl:h-auto w-full;
  /* @apply h-[calc(100vh-150px)]; */
}
.mainImages img {
  @apply w-full max-w-[950px]  h-full object-contain mx-auto;
  /*p-28 xl:p-10 p-3*/
}
.sliderThumbs {
  @apply w-full min-w-[110px] max-w-[110px] -order-1 py-10 !inline-block float-left h-[auto];
}
.prevArrow,
.nextArrow {
  @apply !h-[75%] !w-[75%] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2;
}
.sliderThumbImages {
  @apply p-2;
}
.sliderThumbImages:empty {
  @apply hidden;
}
:global .slick-dots li {
  @apply !m-0;
}
:global .slick-dots {
  @apply !bottom-[30px];
}
:global .slick-dots li.slick-active button:before,
:global .slick-dots li:hover button:before {
  @apply !text-primary;
}
:global .sliderRef {
  @apply relative;
}
:global .sliderRef .slick-arrow {
  @apply h-[35px] w-[35px] bg-gray-200 rounded-full hover:bg-primary transition-all;
}
:global .sliderRef .slick-arrow svg {
  @apply text-secondary;
}
:global .sliderRef .slick-arrow:hover svg {
  @apply text-white;
}
:global .sliderRef .slick-arrow:before {
  @apply hidden;
}
:global .sliderRef .slick-slider .slick-prev {
  @apply !left-2.5;
}
:global .sliderRef .slick-slider .slick-next {
  @apply !right-2.5;
}
:global .slick-arrow {
  @apply min-w-0 text-[25px] leading-5 h-[25px] w-[25px] z-[1];
}
.mainImageSlider :global .slick-list {
  @apply mx-0;
}
.inVisible {
  @apply invisible opacity-0 max-h-0 overflow-hidden;
}
.sliderThumbs :global(.slick-list .slick-slide) {
  @apply !min-w-[60px];
}
.sliderThumbs :global(.slick-list .slick-track) {
  @apply max-h-[calc(100vh-(var(--stickyHeight)+140px))];
}
.sliderThumbs :global(.slick-current) {
  @apply border !border-borderDark/15;
}
.iframe {
  @apply pt-[calc(100vh-(var(--stickyHeight)+60px))] min-h-[400px] relative xl:!pt-[100%];
  @apply 2xl:pt-[calc(100vh-(var(--stickyHeight)+var(--thumbHeight)+70px))];
}
.viewIframe {
  @apply absolute top-0 left-0 w-full h-full;
}
.iframe :global(.loading-root) {
  @apply top-0 h-full bg-white/95 z-[1];
}
.iframe :global(.loading-root img) {
  transform: none !important;
  top: 0 !important;
  left: 0 !important;
}
:global(.sliderWrapper .swiper-vertical) {
  @apply max-w-[80px] min-w-[80px] bg-[white] -order-1;
  @apply 2xl:order-none;
  /*pt-1*/
}
:global(.swiper-horizontal.swiper-thumbs .swiper-slide) {
  /* max-width: 80px;
  min-width: 80px;*/
  min-height: 65px !important;
  max-height: 69px !important;
  height: auto;
  margin-bottom: 2px;
}
:global(.swiper-horizontal.swiper-thumbs .swiper-slide:last-child) {
  margin-right: 0 !important;
}
.swiperThumb :global(.swiper-slide) {
  max-height: 70px !important;
  min-height: 65px !important;
  cursor: pointer;
  height: auto;
  display: flex;
}
.swiperThumb :global(.swiper-slide:last-child) {
  margin-right: 0 !important;
}
.swiperThumb .swiper3d {
  @apply 2xl:min-w-[140px];
}
.swiperThumb .swiper3dWithEngravingThumb {
  @apply 2xl:min-w-[225.5px];
}
:global(.sliderWrapper .swiper-vertical .swiper-slide) {
  min-height: 80px !important;
  min-width: 80px;
}
.swiperThumb :global(.swiper-slide > button),
.swiperThumb :global(.swiper-slide > img) {
  border: 1px solid transparent;
}
:global(.tabbing) .swiperThumb :global(.swiper-slide > div:focus),
:global(.tabbing) .swiperThumb :global(.swiper-slide > img:focus) {
  @apply !-outline-offset-1;
}
:global(.tabbing) .swiperThumb :global(.swiper-slide:focus) {
  @apply !-outline-offset-1;
}
.swiperThumb {
  @apply w-full;
}
/*.swiperThumb :global(.swiper-slide.swiper-slide-thumb-active > div),*/
.swiperThumb :global(.swiper-slide.swiper-slide-thumb-active > img) {
  @apply border-borderDark/60;
}
.icon360 {
  @apply size-full flex flex-1 content-center justify-center bg-grayLight flex-wrap cursor-pointer text-[11px] font-medium text-borderDark/60 max-h-[80px];
}
.icon360Group {
  @apply flex items-center;
}
.icon360 svg {
  @apply !size-[2em];
}
.text360 {
  @apply mt-0 xl:text-xs block;
}
.swiperMain {
  @apply w-full;
  max-height: inherit !important;
  background-color: rgb(250 250 250) /* #fcfcfc */;
}
.swiperMain :global(.swiper-slide img) {
  max-height: 100%;
  margin: 0 auto;
  object-fit: scale-down;

  /* transition: 0.2s ease; */
  @apply relative top-1/2 -translate-y-1/2 left-0 right-0;
  @apply xl:static xl:-translate-y-0;
}
.swiperMain :global(.swiper-slide) .lightSkin {
  @apply absolute xl:!top-0;
}
.buttonNav :global(.arrow) {
  @apply absolute top-1/2 z-[4] min-w-0 text-[25px] leading-5 h-[35px] w-[35px] bg-white/30 border-[2px] hover:bg-white/70 hover:border-primary/70 hover:text-primary/70 border-borderDark/70 text-borderDark/70 rounded-full transition-all cursor-pointer -translate-y-1/2;
  @apply md:h-[30px] md:w-[30px];
}
.buttonNav :global(.arrow.swiper-button-disabled) {
  @apply !opacity-0 pointer-events-none;
}
.buttonNav :global(.arrow svg) {
  @apply !h-[1em] !w-[1em];
}
.buttonNav :global(.arrow-left) {
  @apply !left-2.5;
}
.buttonNav :global(.arrow-right) {
  @apply right-2.5;
}
.swiperThumb :global(.swiper-wrapper) {
  @apply inline-flex w-auto h-auto;
}
.buttonNavThumb :global(.arrow) {
  @apply absolute top-0 h-full z-[1] bg-white px-1 cursor-pointer;
  @apply xl:visible;
}
.buttonNavThumb :global(.arrow.disabled) {
  @apply pointer-events-none opacity-0;
}
.buttonNavThumb :global(.arrow.arrow-left) {
  left: 0;
}
.buttonNavThumb :global(.arrow.arrow-right) {
  right: 0;
}
.frameText {
  @apply absolute bottom-5 left-0 right-0 pointer-events-none text-lightText flex items-center justify-center px-3;
  @apply xl:justify-start md:bottom-5 sm:text-xs xs:text-[11px];
}
.frameText svg {
  @apply !w-[1.8em] !h-[1.8em];
}
.disableButtons {
  opacity: 0;
  visibility: hidden;
}
.disableThumbs {
  max-height: 0;
  max-width: 0;
  overflow: hidden;
}
/* For mobile devide fixed height for full width slider  */
.swiperMain :global(.swiper-slide img) {
  @apply xl:max-h-[580px];
}
.iframe {
  @apply xl:!pt-[580px];
  /* transition: 0.2s ease; */
}
.swiperThumb {
  @apply max-w-[600px] 2xl:!px-[30px];
}
:global(.stickySlider .sliderWrapper) .iframe {
  @apply xl:!pt-[350px];
}
:global .stickySlider .sliderWrapper .swiper-slide img {
  @apply xl:max-h-[350px];
}
.notFound {
  @apply absolute inset-0;
}
.imgNotFound {
  @apply opacity-0;
}
.handView .switchView {
  @apply right-[2px];
}
.handView {
  @apply text-primary;
}
.handView .toggleWrapper {
  @apply bg-primary;
}
.toggleWrapper {
  @apply relative w-[32px] h-[18px] bg-[gray] border rounded-[50px] border-solid border-[#ededed];
  box-shadow: 0 0 0 1px #fff;
}
.switchView {
  @apply w-[12px] h-[calc(100%-4px)] bg-white font-[bold] absolute right-[calc(100%-14px)] z-[2] transition-[0.35s] duration-[ease] rounded-[50%] top-[2px];
}
.switchControl {
  @apply bg-[#a8ad00] h-[50px] w-[100px] font-[bold] text-white transition-all duration-[0.8s] ease-[ease] rounded-[18px];
}
.toggleViewbtn {
  @apply bg-transparent gap-2 font-medium h-auto m-0 border-[none] border-0 flex-row-reverse text-[13px] px-3 py-0.5;
  @apply sm:text-[0] sm:gap-0 min-w-0 pl-1.5;
}
.handViewConfig {
  @apply absolute z-[1] left-auto right-0 top-auto bottom-3 inline-flex items-center;
}
.skinToneWrap {
  @apply max-w-0 overflow-hidden transition-all ease-in-out duration-300 inline-flex opacity-0 invisible justify-end;
}
.skinToneWrapInner {
  @apply min-w-[58px] gap-1.5 z-[1] relative inline-flex;
  @apply xs:gap-0.5;
}
.skinToneWrap.activeSkin {
  @apply max-w-[100px] overflow-visible opacity-100 visible;
}
.skinLight {
  background-color: #c18e70;
}
.skinDark {
  background-color: #77533d;
}
.skinNormal {
  background-color: #e4c2a1;
}
.skinToneWrapInner > button {
  @apply size-[1.125rem] min-w-[1.125rem] rounded-full border border-white shadow-white shadow-[0_0_1px_1px] my-0.5;
}
.active {
  @apply !shadow-primary/60 !shadow-[0_0_1px_2px] !border-2;
}
:global(.sliderWrapper .swiper-vertical) .swiper3d {
  @apply !max-h-[170px] !min-h-[170px] flex flex-col;
}

:global(.sliderWrapper .swiper-vertical) .swiper3dWithEngravingThumb {
  @apply !max-h-[260px] !min-h-[260px] flex flex-col;
}
.icon360Hand {
  @apply mt-[10px] 2xl:mt-0 2xl:ml-2.5 h-full flex-1 flex border-borderDark/0 bg-grayLight max-h-[80px];
}
.icon360Hand > img {
  @apply object-cover;
}
:global(.swiper-slide) > .activeHand.actives {
  @apply !border-borderDark/60;
}
.activeHand {
  @apply pointer-events-none;
}
:global(.swiper-horizontal.swiper-thumbs) .swiper3d {
  @apply flex min-w-[147px];
}
:global(.swiper-horizontal.swiper-thumbs) .swiper3dWithEngravingThumb {
  @apply flex min-w-[225.5px];
}
:global(.swiper-slide.swiper-slide-thumb-active) > .active3dView,
:global(.swiper-slide.swiper-slide-active) > .active3dView {
  @apply !border-borderDark/60;
}
/*.handViewMobilesDisable{*/
/*  @apply absolute bottom-5 right-[0.625rem] h-8 sm:h-6 z-[2] overflow-hidden ;*/
/*}*/
.handViewMobiles {
  @apply absolute bottom-5 right-[0.625rem] h-8 sm:h-6 z-[2] overflow-hidden;
}
.handViewMobiles > button {
  @apply size-full flex items-center gap-0.5 px-2 font-medium sm:text-xs;
}
.handViewMobiles > button > img {
  @apply !max-h-full;
}
.handViewMobiles > button > svg {
  @apply !size-[2.2em];
}
/*Made changes only for safari */
:global(.safari-macos) .swiperMain :global .swiper-wrapper {
  max-height: inherit;
}
:global(.safari-macos) .swiperMain :global .swiper-wrapper .swiper-slide {
  max-height: inherit;
}
:global(.safari-macos) .swiperMain :global .swiper-wrapper .swiper-slide > img {
  top: calc((50vh - (var(--stickyHeight) / 2)) - (62px / 2));
  height: auto;
}
.thumbShimmer {
  margin-bottom: 10px;
}
.thumbShimmerAnim {
  @apply animate-pulse flex space-x-4;
}
.thumbShimmerBg {
  @apply bg-borderDark/10 h-[78px] w-[78px];
}
@media screen and (max-width: 1439px) {
  .certificateWithThumb :global(.swiper-wrapper) {
    margin-left: calc(-1 * (var(--thumbHeight) + 10px));
  }
}
/*This is only for details page thumb as if Certificate is added, rest of the thumbs are not correctly displayed */
/*close of Made changes only for safari */
@media screen and (min-width: 1280px) {
  :global(.safari-macos)
    .swiperMain
    :global
    .swiper-wrapper
    .swiper-slide
    > img {
    max-height: inherit;
  }
}
@media screen and (max-width: 575px) {
  .iframe {
    @apply !pt-[100%] min-h-[300px];
  }
}
@media screen and (min-width: 600px) and (max-height: 650px) and (max-width: 1279px) {
  .iframe {
    @apply !pt-[calc(300px-var(--stickyHeight))];
  }
  :global .sliderWrapper {
    @apply !max-h-[calc(300px-var(--stickyHeight))];
  }
  .swiperMain :global(.swiper-slide img) {
    @apply max-h-full;
  }
}
.skinView {
  @apply absolute z-[1] bottom-0;
}
.handViewPosition {
  @apply relative h-full;
}
.handViewPosition > img {
  @apply object-contain object-bottom;
}
:global(.safari-macos) .handViewPosition img {
  @apply h-auto;
}
.lightSkin {
  @apply z-[2] h-[100vh] xl:h-full;
}
.darkSkin {
  @apply z-[1] xl:!top-0 xl:z-[-1] h-[100vh] xl:h-full;
}
.displayBlock {
  @apply block;
}
.displayHidden {
  @apply hidden;
}
.rangeSlider {
  @apply absolute top-1/2 left-[120px] z-[2] -translate-y-1/2 py-2.5;
  @apply 2xl:left-[20px] xl:left-[20px] xl:top-[20px] xl:translate-y-0 select-none;
}
.rangeSlider :global .rc-slider-vertical {
  height: 250px;
  @apply xl:h-[200px] md:h-[150px] sm:h-[100px] px-3.5 w-[35px];
}
.rangeSlider :global .rc-slider-vertical .rc-slider-track {
  @apply bg-transparent;
}
.rangeSlider :global .rc-slider-vertical .rc-slider-rail {
  background: linear-gradient(
    0deg,
    rgba(228, 194, 161, 1) 0%,
    rgba(82, 59, 44, 1) 100%
  );
  width: 6px;
}
.rangeSlider :global .rc-slider-vertical .rc-slider-handle {
  @apply bg-[var(--handleBackground)] border border-[var(--handleBackground)] -ml-[4px] size-5;
}
.rangeSlider :global .rc-slider-vertical .rc-slider-handle:active {
  box-shadow: 0 0 5px black;
}
.rangeSlider :global .rc-slider-vertical .rc-slider-handle-dragging {
  @apply !border-borderDark !shadow-[0_0_0_3px] !shadow-borderDark/40;
}
:global .sliderWrapper .disableFrame > iframe {
  pointer-events: none;
}
.buttonViewChange {
  @apply -outline-offset-1 border border-gray-500 rounded-sm bg-white;
}
.buttonViewChangeDisabled {
  @apply border border-gray-300 text-gray-300 cursor-not-allowed bg-white;
}
.viewIframeContainer {
  @apply w-full max-w-[580px] relative mx-auto;
}
.viewIframeWrapper {
  @apply pt-[100%] sm:min-h-[390px];
}
.viewIframeInner {
  @apply w-full pt-[var(--paddingTop)] absolute inset-0 overflow-hidden;
}
.isLandScape {
}
.isLandScapeSmaller {
  @apply sm:max-w-[300px];
  /*sm:h-[390px]*/
}
.isLandScapeSmaller .viewIframeWrapper {
  @apply min-h-[335px];
}
.isSquare .viewIframeItem > iframe {
  @apply max-w-[calc(100%-90px)] sm:max-w-full sm:w-[300px] sm:h-[390px] mx-auto inset-0;
}
.isPortrait {
}
.viewIframeItem {
  @apply mx-auto absolute inset-0 overflow-hidden h-[200%];
}
.testView {
  @apply !w-auto;
}
:global(.mainSlider > .swiper-wrapper > .swiper-slide:not(.swiper-slide-active))
  .viewIframeWrapper {
  padding-top: 50%;
}
/*When sticky mode */
@media screen and (max-width: 1279px) {
  :global(.sliderWrapper) .iframe {
    min-height: unset;
  }
  :global .sliderWrapper .swiper-slide img {
  }
  :global(.stickySlider .sliderWrapper) .iframe {
    @apply !pt-[calc(580px-var(--scroll-offset))];
    /* @apply xl:!pt-[calc(580px)]; */
    @apply min-h-[220px];
  }
  :global .stickySlider .sliderWrapper .swiper-slide > img {
    @apply !max-h-[calc(580px-var(--scroll-offset))];
    @apply min-h-[220px];
  }
  .iframe {
  }
}
@media screen and (min-width: 1025px) and (max-width: 1279px) {
  :global(.sliderWrapper) .iframe {
    min-height: unset;
  }
  :global .sliderWrapper .swiper-slide img {
  }
  :global(.stickySlider .sliderWrapper) .iframe {
    /* @apply !pt-[calc(580px-var(--scroll-offset))]; */
    @apply xl:!pt-[calc(580px)];
    @apply min-h-[580px];
  }
  :global .stickySlider .sliderWrapper .swiper-slide > img {
    /* @apply !max-h-[calc(580px-var(--scroll-offset))]; */
    @apply min-h-[580px];
  }
  .iframe {
  }
}
@media screen and (max-width: 579px) {
  :global .stickySlider .sliderWrapper .swiper-slide > img {
    @apply xl:!max-h-[calc(100vw-var(--scroll-offset))];
  }
  :global(.stickySlider .sliderWrapper) .iframe {
    @apply xl:!pt-[calc(100vw-var(--scroll-offset))];
  }
}
@media screen and (max-width: 1279px) and (max-height: 550px) {
  :global(.stickySlider .sliderWrapper) .iframe {
    @apply xl:!pt-[250px];
  }
  :global .stickySlider .sliderWrapper .swiper-slide > img {
    @apply xl:max-h-[250px];
  }
}
@media screen and (min-width: 600px) and (max-height: 500px) and (max-width: 1279px) {
  :global(.stickySlider .sliderWrapper) .iframe {
    @apply !pt-[calc(300px-(var(--stickyHeight)+var(--scroll-offset)))];
  }
  :global .stickySlider .sliderWrapper .swiper-slide > img {
    @apply !max-h-[calc(300px-(var(--stickyHeight)+var(--scroll-offset)))];
  }
}
@media screen and (min-width: 600px) and (max-height: 700px) and (max-width: 1279px) {
  .iframe {
    @apply !pt-[calc(300px-var(--stickyHeight))];
  }
  :global .sliderWrapper {
    @apply !max-h-[calc(300px-var(--stickyHeight))];
  }
  .swiperMain :global(.swiper-slide img) {
    @apply max-h-full;
  }
  :global(.stickySlider .sliderWrapper) .iframe {
    @apply !pt-[calc((300px-var(--stickyHeight))-var(--scroll-offset))];
  }
  :global .stickySlider .sliderWrapper .swiper-slide > img {
    @apply !max-h-[calc((300px-var(--stickyHeight))-var(--scroll-offset))];
  }
  :global .slider-wrapper-stick {
    @apply !pt-[calc(300px-(var(--stickyHeight)))];
  }
}
.engravingView {
  @apply sm:bottom-12 bottom-14;
  /*@apply xl:top-1/2 xl:-mt-0.5 xl:left-2 xl:size-12 xl:-translate-y-1/2 xl:items-center;*/
}
.engravingView img {
  @apply !translate-y-0;
}
.frame360 > svg {
  @apply !size-[2em];
}
:global(.sliderWrapper .swiper-horizontal) .engravingPreview {
  @apply ml-2.5;
}
:global(.sliderWrapper .swiper-vertical) .engravingPreview {
  @apply mt-2.5;
}
