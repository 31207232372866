.helpWrap {
    @apply min-h-[inherit] flex w-full max-w-[200px] justify-end items-center;
}
.helpInner {
    @apply flex justify-end items-center gap-x-5 z-[2];
}
.link {
    @apply flex items-center text-default hover:text-black cursor-pointer;
}
.icon {
    @apply text-inherit mr-1;
}
.active {
    position: relative;
}
.linkText{
    @apply flex items-center leading-none font-medium;
}
.helpWrap{
    @apply md:!max-w-[60px] sm:!max-w-[40px] xs:!max-w-[30px];
}
@media screen and (max-width: 1079px) {
    .helpWrap {
        @apply max-w-[80px] relative;
    }
    .helpWrap :global svg{
        @apply !size-[2em] xs:!size-[1.5em] mr-0;
    }
    .logoLink {
        @apply w-[80px] md:w-[60px];
    }
    .helpInner {
        /*@apply flex-col absolute top-[calc(100%)] w-[150px] items-center bg-grayLight ;*/
    }
    /*.helpInner:not(.active) {*/
    /*    @apply hidden;*/
    /*}*/
    .linkText{
        @apply hidden;
    }
    .link {
        @apply w-full px-3 py-3 sm:p-1.5;
    }
}


/**/
.details button {
    @apply w-full;
}
.dialogBody {
    @apply px-4 py-6 border-t border-solid border-secondary/20 cursor-pointer hover:bg-tableRowHover;
    @apply xl:py-5 focus:bg-tableRowHover;
}
.dialogDetail {
    @apply flex gap-4 items-center;
}
.dialogImg {
    @apply w-20 h-20 object-contain;
}
.dialogText {
    @apply flex gap-2 flex-col text-secondary;
}
.dialogTitle {
    @apply text-base font-primary font-medium;
}
.dialogParagraph {
    @apply text-sm text-gray-500;
}