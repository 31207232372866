:global(.hk_detailsContainer) {
  @apply w-full flex flex-col gap-2.5 tracking-wider flex-1 flex-shrink-0;
}
:global(.hk_detailHeading) {
  @apply flex justify-between text-xl font-medium gap-2.5 tracking-tight font-secondary text-left;
  @apply lg:text-lg;
}
:global(.hk_details_wrap) {
  @apply text-black font-light text-sm;
}
:global(.hk_detail) {
  @apply flex justify-between py-3 border-b border-solid border-black/10 last:border-b-0 -tracking-[0.01em] font-medium;
  @apply lg:text-[13px];
}
